<template>
  <v-card
    :loading="loading"
    class="service-item bg-grey-gradient"
    dark
    @click="$emit('click', value)"
  >
    <div class="service-type-img control" :style="getImageStyle()"></div>

    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <div
      class="service-status"
      :class="{
        primary: value.status.code === 'PUBLISHED',
        'teal darken-1': value.status.code === 'PENDING',
      }"
    >
      {{ value.status.name }}
    </div>
    <div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-card-title class="service-title pb-0 mb-0" v-bind="attrs" v-on="on"
            >{{ value.code }}
          </v-card-title>
        </template>
        <span class="brief">{{ value.name }}</span>
      </v-tooltip>

      <v-card-text class="pa-0 ma-0">
        <v-row class="justify-space-between pa-0 ma-0">
          <v-col cols="12" class="pt-0">
            <v-row align="center" class="mx-0">
              <v-rating
                :value="4.5"
                color="amber"
                dense
                half-increments
                readonly
                size="14"
              ></v-rating>

              <div class="grey--text ms-4">4.5 (413)</div>
            </v-row>

            <div class="my-2 text-subtitle-1">
              {{ formatter.price(value.minPrice) }}-{{
                formatter.price(value.maxPrice)
              }}
            </div>

            <ServiceFitRateVue dark :value="value" small></ServiceFitRateVue>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
  </v-card>
</template>
  
  <script>
import { FormatterHelper } from "../../../helpers/formatter.helper";
import ServiceFitRateVue from "../../atoms/service/ServiceFitRate.vue";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      formatter: FormatterHelper,
      loading: false,
      show: false,
    };
  },
  components: {
    ServiceFitRateVue,
  },
  methods: {
    onOpen() {
      this.$emit("onOpen", this.value);
    },
    onEdit() {
      this.$emit("onEdit", this.value);
    },

    getImageStyle() {
      return {
        background: `url(` + this.value.type.image + ")",
        "background-size": "1200px 1200px",
        "background-position": "50% 50%",
        "background-repeat": "no-repeat",
      };
    },
  },
};
</script>
  
  
  <style lang="scss" scoped>
.brief {
  text-transform: uppercase;
}
.service-item {
  z-index: 2;
  overflow: hidden;
  max-width: 25vw;
  height: 230px;
  transition: all 0.2s;

  &:hover {
    transform: scale(1.05);
  }

  .service-type-img {
    position: absolute;
    height: 100%;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    -webkit-mask-image: -webkit-gradient(
      linear,
      left top,
      left right,
      from(rgba(0, 0, 0, 1)),
      to(rgba(0, 0, 0, 0))
    );
    mask-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)
    );
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .service-title {
    text-transform: uppercase;
  }

  .service-status {
    opacity: 0.5;
    transform: rotate(45deg);
    background-color: red;
    color: white;
    position: absolute;
    right: -40px;
    width: 150px;
    top: 0;
    text-transform: uppercase;
    /* overflow: hidden; */
    /* margin: 0 auto; */
    text-align: center;
    top: 22px;
  }
}
</style>