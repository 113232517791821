<template>
  <AView
    :value="value"
    :actions="actions"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onCreate="onCreate"
  >
    <template v-slot:content="{}">
      <v-card class="transparent mb-0 pb-0 pa-2 elevation-0">
        <v-card-text class="pa-0 ma-0">
          <LeadAnalyzerForm v-model="data"> </LeadAnalyzerForm>
        </v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary" @click="onAnalyze">
            Analyze
            <v-icon >mdi-reload</v-icon>
          </v-btn>
        </v-card-actions> -->
      </v-card>
      <v-card class="transparent ma-0 pa-2 elevation-0 pt-0">
        <v-card-text class="pa-0 ma-0">

          <!-- Terms -->
          <FormSection
            class="mt-0"
            :label="'Found Terminology'"
            :editable="false"
            underline
            
            v-if="analyzeResult.terms && analyzeResult.terms.length"
          ></FormSection>
          <v-slide-group class="pa-2" center-active show-arrows>
            <v-slide-item
              v-for="result in analyzeResult.terms"
              :key="result.id"
            >
              <GlossaryTermItemSmall
                @click="() => onTermClick(result.term)"
                :value="result.term"
              ></GlossaryTermItemSmall>
            </v-slide-item>
          </v-slide-group>

          <!-- Domains -->
          <FormSection
            class="mt-0"
            :label="'Business Domains'"
            :editable="false"
            underline
            
            v-if="analyzeResult.domains && analyzeResult.domains.length"
          ></FormSection>
          <v-slide-group class="pa-2" center-active show-arrows>
            <v-slide-item
              v-for="result in analyzeResult.domains"
              :key="result.id"
            >
              <BusinessDomainItemSmall
                @click="() => onDomainClick(result.domain)"
                :value="result.domain"
              ></BusinessDomainItemSmall>
            </v-slide-item>
          </v-slide-group>

          <!-- Services -->
          <FormSection
            class="mt-0"
            :label="'Recommended Services'"
            :editable="false"
            underline
            
            v-if="analyzeResult.services && analyzeResult.services.length"
          ></FormSection>
          <v-slide-group class="pa-2" center-active show-arrows>
            <v-slide-item
              v-for="result in analyzeResult.services"
              :key="result.id"
            >
              <ServiceItemSmall
                class="ma-2"
                :value="result.service"
                @click="() => onServiceClick(result.service)"
              ></ServiceItemSmall>
            </v-slide-item>
          </v-slide-group>
          <!-- Case Studies -->
          <FormSection
            class="mt-0"
            :label="'Case Studies'"
            :editable="false"
            underline
            
            v-if="analyzeResult.caseStudies && analyzeResult.caseStudies.length"
          ></FormSection>

          <v-slide-group class="pa-2" center-active show-arrows>
            <v-slide-item
              v-for="result in analyzeResult.caseStudies"
              :key="result.id"
            >
              <CaseStudyItemSmall
                v-if="result.caseStudy"
                :value="result.caseStudy"
                @click="() => onCaseStudyClick(result.caseStudy)"
              ></CaseStudyItemSmall>
            </v-slide-item>
          </v-slide-group>

          <!-- Questions -->
          <FormSection
            class="mt-0"
            :label="'Questions you can ask'"
            :editable="false"
            underline
            
            v-if="analyzeResult.questions && analyzeResult.questions.length"
          ></FormSection>

          <v-list
            two-line
            v-if="analyzeResult.questions && analyzeResult.questions.length"
            class="transparent"
          >
            <v-list-item-group
              active-class="primary--text"
              class="transparent"
              multiple
            >
              <template v-for="(result, index) in analyzeResult.questions">
                <v-list-item
                  :key="result.id"
                  class="transparent"
                  v-if="result.question"
                  @click="() => onQuestionClick(result.question)"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title>{{
                        result.question.name
                      }}</v-list-item-title>

                      <v-list-item-subtitle class="secondary--text">
                        {{ result.question.goal }}</v-list-item-subtitle
                      >
                    </v-list-item-content>

                    <v-list-item-action>
                      <!-- <v-list-item-action-text
                        v-text="item.action"
                      ></v-list-item-action-text> -->

                      <v-icon v-if="!active" color="grey lighten-1">
                        mdi-star-outline
                      </v-icon>

                      <v-icon v-else color="yellow darken-3"> mdi-star </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>

                <v-divider
                  v-if="index < analyzeResult.questions.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>

          <!-- Experience Records -->
          <FormSection
            class="mt-0"
            :label="'Notes from previous experience'"
            :editable="false"
            underline
            
            v-if="
              analyzeResult.experienceNotes &&
              analyzeResult.experienceNotes.length
            "
          ></FormSection>

          <v-slide-group class="pa-2" center-active show-arrows>
            <v-slide-item
              v-for="result in analyzeResult.experienceNotes"
              :key="result.id"
            >
              <v-chip
                class="ma-2"
                text-color="white"
                v-if="result.experienceNote"
                @click="() => onExperienceNoteClick(result.experienceNote)"
              >
                {{ result.experienceNote.name }}
              </v-chip>
            </v-slide-item>
          </v-slide-group>
        </v-card-text>
      </v-card>
    </template>
  </AView>
</template>
    
    
    
  <script>
import { mapState } from "vuex";
import FormSection from "../../../../../components/wad/atoms/common/FormSection.vue";
import GlossaryTermItemSmall from "../../../../../components/wad/organisms/glossary/GlossaryTermItemSmall.vue";
import BusinessDomainItemSmall from "../../../../../components/wad/organisms/knowledgeBase/businessDomains/BusinessDomainItemSmall.vue";
import CaseStudyItemSmall from "../../../../../components/wad/organisms/knowledgeBase/caseStudies/CaseStudyItemSmall.vue";
import AView from "../../../../../components/wad/organisms/layout/A-View.vue";
import LeadAnalyzerForm from "../../../../../components/wad/organisms/leadAnalyzer/forms/LeadAnalyzerForm.vue";
import ServiceItemSmall from "../../../../../components/wad/organisms/services/ServiceItemSmall.vue";
import { GUI } from "../../../GrapUI.config.js";
import { ServiceCreation } from "../app.gh.js";

export default {
  name: "LeadAnalyzer",
  props: {
    value: {
      default: () => {},
    },
  },
  components: {
    AView,
    LeadAnalyzerForm,
    ServiceItemSmall,
    CaseStudyItemSmall,
    FormSection,
    GlossaryTermItemSmall,
    BusinessDomainItemSmall,
  },
  computed: mapState("LeadAnalyzerStore", ["analyzeResult"]),
  created() {
    //   this.$store.dispatch("ServiceStore/GetServicesList");
  },
  data() {
    return {
      page: 1,
      scene: GUI.scene,
      model: null,
      serviceIndex: null,
      termIndex: null,
      data: {},
      actions: [
        {
          name: "Create",
          description: "Will create new Service",
          icon: "mdi-plus",
          color: "#23842a",
          on: "onCreate",
        },
        {
          name: "Close",
          description: "Will close this window",
          icon: "mdi-trash-can-outline",
          color: "#0e1721",
          on: "onClose",
        },
      ],
    };
  },
  methods: {
    async onCreate() {
      await this.scene.add(this.value, ServiceCreation, {});
      // RView.focus();
    },
    onSelect(service) {
      this.$store.dispatch("ServiceStore/SetActiveService", {
        from: this.value,
        service,
      });
    },
    onServiceClick(service) {
      this.$store.dispatch("ServiceStore/SetActiveService", {
        from: this.value,
        service,
      });
    },
    onTermClick(term) {
      this.$store.dispatch("GlossaryStore/SetActiveTerm", {
        from: this.value,
        term,
      });
    },
    onQuestionClick(question) {
      this.$store.dispatch("QuestionStore/SetActiveQuestion", {
        from: this.value,
        question,
      });
    },
    onDomainClick(businessDomain) {
      this.$store.dispatch("BusinessDomainStore/SetActiveBusinessDomain", {
        from: this.value,
        businessDomain,
      });
    },
    onExperienceNoteClick(experienceNote) {
      this.$store.dispatch("ExperienceNoteStore/SetActiveExperienceNote", {
        from: this.value,
        experienceNote,
      });
    },
    onCaseStudyClick(caseStudy) {
      this.$store.dispatch("CaseStudyStore/SetActiveCaseStudy", {
        from: this.value,
        caseStudy,
      });
    },
  },
  watch: {
    activeServiceId(newVal) {
      if (newVal) {
        const index = this.services.findIndex((el) => el.id === newVal);
        this.active = index;
      }
    },
    "data.input"(newVal) {
      if (newVal) {
        console.log("newVal: ", newVal);

        this.$store.dispatch("LeadAnalyzerStore/Analyze", {
          data: this.data,
        });
      } else {
        this.$store.dispatch("LeadAnalyzerStore/clearAnalyze");
      }
    },
  },
};
</script>
    
    
    
    <style scoped lang="scss" >
.users-list {
  height: 100%;
  width: 100%;
  // ::v-deep button {
  //   background: white !important;
  // }
}
</style>