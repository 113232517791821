<template>
  <v-card
    :loading="loading"
    class="case-study-item-small bg-grey-gradient"
    dark
    @mouseover="() => $emit('mouseover', value)"
    @mouseleave="() => $emit('mouseleave', value)"
  >
    <div :class="{ active }" class="item-active-indicator"></div>
    <div class="click-area" @click="$emit('click', value)">
      <div
        v-if="value.status"
        class="term-status"
        :class="{
          primary: value.status.code === 'PUBLISHED',
          'teal darken-1': value.status.code === 'PENDING',
        }"
      >
        {{ value.status.name }}
      </div>
      <v-card-title class="term-title">
        {{ cutFormatter(value.name, 40) }}
        <a class="demo-link" :href="value.web_url" target="blank">
          {{ value.web_url }}</a
        >
        <v-rating
          :value="4.5"
          color="amber"
          dense
          half-increments
          readonly
          size="14"
        ></v-rating>
      </v-card-title>
      <v-card-text>
        <v-row class="justify-space-between">
          <v-col cols="12" class="ma-0 pa-0">
            <p class="simple-text deliverable-description">
              {{ cutFormatter(value.summary, 70) }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </div>

    <v-card-actions
      class="card-actions-bottom"
      v-if="value.tags && value.tags.length"
    >
      <v-slide-group
        class="ma-0 pa-0 slider-some"
        center-active
        show-arrows
        v-if="value.tags && value.tags.length"
      >
        <v-slide-item
          v-for="item in value.tags"
          :key="item.tag.id"
          v-slot="{ toggle }"
        >
          <v-chip
            class="ma-2"
            :color="item.tag.color"
            @click.prevent.stop="toggle"
            label
            text-color="white"
          >
            <v-icon v-if="item.tag.icon" left> {{ item.tag.icon }} </v-icon>
            {{ item.tag.name }}
          </v-chip>
        </v-slide-item>
      </v-slide-group>
    </v-card-actions>
  </v-card>
</template>
  
  <script>
import { FormatterHelper } from "../../../../helpers/formatter.helper";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      cutFormatter: FormatterHelper.cutString,

      loading: false,
      show: false,
    };
  },
  components: {},
  methods: {
    onOpen() {
      this.$emit("onOpen", this.value);
    },
    onEdit() {
      this.$emit("onEdit", this.value);
    },
  },
};
</script>
  
  
  <style lang="scss" scoped>
.case-study-item-small {
  z-index: 2;
  overflow: hidden;
  max-width: 300px;
  height: 240px;
  transition: all 0.2s;
  margin: 8px;

  &:hover {
    transform: scale(1.05);
  }
  .term-title {
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .brief {
    color: #cfd8dc !important;
  }

  .term-status {
    transform: rotate(45deg);
    background-color: red;
    color: white;
    position: absolute;
    right: -40px;
    width: 150px;
    top: 0;
    text-transform: uppercase;
    /* overflow: hidden; */
    /* margin: 0 auto; */
    text-align: center;
    top: 22px;
  }

  .demo-link {
    display: block;
    font-size: 14px;
    text-transform: none;
  }
}
</style>