<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <v-row>
      <v-col cols="12" class="header-editor-col">
        <HelpFormInputVue
          dark
          :rows="6"
          v-model="form.input"
          :rules="analyzerInputRules"
          label="Lead Input"
          :helpText="'You can put there any input from customer, requirements or even some sort of communication you had with a customer to be analyzed'"
          required
          :textarea="true"
        ></HelpFormInputVue>
      </v-col>
    </v-row>
  </v-form>
</template>
    
    
    <script>
import HelpFormInputVue from "../../../atoms/common/inputs/HelpFormInput.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Form
      pValid: this.valid,
      form: this.value,
      // ======rules
      analyzerInputRules: [(v) => !!v || "Please provide input to analyze"],
    };
  },
  components: {
    HelpFormInputVue,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.form = newVal;
      },
      deep: true,
    },
    form: {
      handler(newVal) {
        this.form.previous_service_id = newVal?.prevService?.id;
        this.form.next_service_id = newVal?.nextService?.id;

        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
    
    
<style lang="scss" scoped>
.header-editor-col {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  flex-grow: 1;
  max-width: 100%;
}
</style>